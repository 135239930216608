export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue 3 i18n"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Vue i18n next example using vue-i18n-next"])},
  "navigation": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "meals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahlzeiten"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen"])},
    "deliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferungen"])},
    "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeln"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
    "titles": {
      "openLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene links"])},
      "loggedLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links für User"])},
      "masterLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links für Master"])},
      "adminLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links für Admin"])}
    }
  },
  "titles": {
    "userList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerliste"])},
    "customerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenliste"])},
    "mealList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahlzeitenliste"])},
    "invoiceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungenliste"])},
    "deliveryList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferungenliste"])},
    "menuList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuslist"])},
    "itemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikellist"])},
    "priceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preislist"])},
    "invoiceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsnumber"])}
  },
  "dateTime": {
    "Week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
    "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
    "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
    "monthsLong": {
      "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
      "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
      "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
      "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
      "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
      "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
      "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])}
    }
  },
  "objects": {
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element"])}
  },
  "models": {
    "user": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Addresse"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert am"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validieren"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
    },
    "meal": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "group_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen-ID"])},
      "invoice_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung ID"])},
      "meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahlzeit"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Datum"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Datum"])},
      "portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portionen"])},
      "half_portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1/2 Portionen"])},
      "portion_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Portion"])},
      "half_portion_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro 1/2 Portion"])},
      "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summen"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "repetitions": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt es eine Wiederholung?"])},
        "repetition_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Art der Wiederholung?"])},
        "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmaliges Ereignis"])},
        "every_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Woche"])},
        "nb_occurrences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Vorkommnisse"])},
        "until_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis zu einem bestimmten Datum"])}
      },
      "validations": {
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie das Startdatum aus...."])},
        "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie das Enddatum aus...."])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Kundenname ein!"])},
        "portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Anzahl von Portionen!"])},
        "half_portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Anzahl von 1/2 Portionen!"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen Kommentar!"])},
        "occurrences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Anzahl von Vorkommnisse!"])}
      }
    },
    "invoice": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatt"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeit"])},
      "validations": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Rabatt ein!"])}
      },
      "generateInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung herstellen"])},
      "deleteInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung löschen"])},
      "statusFilter": {
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
        "invoiced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrechnet"])},
        "printed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedruckt"])}
      }
    },
    "menu": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "validations": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie das Datum aus...."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Title ein!"])}
      }
    },
    "customer": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden ID"])},
      "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höflichkeit"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strasse"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortschaft"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "locality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortschaft"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Adresse"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactiv"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeninformationen"])},
      "validations": {
        "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen sie die Höflichkeit aus..."])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Nachnamen ein!"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Vornamen ein!"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Strassennamen ein!"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Gebäudenummer ein!"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Postleitzahl ein!"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Ortschaft ein!"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Telefonnummer ein!"])},
        "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Mobiltelefonnummer ein!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die E-mail Adresse ein!"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen sie den Status aus..."])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenspezifische Informationen"])}
      }
    },
    "item": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert am"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
      "validations": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Namen ein!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Beschreibung ein!"])}
      }
    },
    "price": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "item_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel id"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "validity_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit vom"])},
      "validity_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit bis"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert am"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "validations": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Betrag ein!"])},
        "validity_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie das Startdatum de Gültigkeit aus...."])},
        "validity_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie das Enddatum der Gültigkeit aus..."])}
      }
    }
  },
  "form": {
    "actions": {
      "filtersOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern auf"])},
      "newCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Kunde"])},
      "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Benutzer"])},
      "newMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Menu"])},
      "newItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Artikel"])},
      "newPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Preis"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
      "printPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als pdf drucken"])},
      "printLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetten drucken"])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : ein einzelnes Element oder mehrere in der Reihe?"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])}
    },
    "scope": {
      "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nur einer"])},
      "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab jetzt"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle"])}
    },
    "cards": {
      "meals": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Mahlzeit herstellen"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahlzeit bearbeiten"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahlzeit anzeigen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahlzeit löschen"])}
        }
      },
      "invoices": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Rechnung herstellen"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung bearbeiten"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung anzeigen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung löschen"])}
        }
      },
      "customers": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Kunde herstellen"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde bearbeiten"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde anzeigen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde löschen"])}
        }
      },
      "users": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Benutzer herstellen"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer bearbeiten"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer anzeigen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer löschen"])}
        }
      },
      "menus": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Menü herstellen"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü bearbeiten"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü anzeigen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü löschen"])}
        }
      },
      "items": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Artikel herstellen"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel bearbeiten"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel anzeigen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel löschen"])}
        }
      },
      "prices": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Preis herstellen"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis bearbeiten"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis anzeigen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis löschen"])}
        }
      }
    },
    "infos": {
      "edit": {
        "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur diese Ressource bearbeiten."])},
        "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle zukünftigen Ressourcen bearbeiten ab diese."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Ressourcen bearbeiten in der komplete Serie."])}
      },
      "delete": {
        "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur diese Ressource löschen."])},
        "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle zukünftigen Ressourcen löschen ab diese."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Ressourcen löschen in der komplete Serie."])},
        "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher Sie wollen diese Ressource löschen?"])}
      }
    },
    "options": {
      "weekdaysShort": {
        "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
        "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
        "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])},
        "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
        "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
        "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])}
      },
      "weekdaysLong": {
        "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
        "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
        "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
        "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
        "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
        "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])},
        "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])}
      }
    }
  },
  "alerts": {
    "invoices": {
      "noMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Mahlzeiten im aktuellen Monat registriert!"])},
      "noInvoiceWithSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Rechnungen, die mit dieser Auswahl angezeigt werden können!"])}
    }
  }
}