<template>
    <main class="mb-3">
        <SideBar />

        <!-- Load symbols for alert diaplay fields -->
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
            <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </symbol>
            <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
            </symbol>
            <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </symbol>
        </svg>

        <!-- Display alert display field if unlogged or not on login page -->
        <div v-if="!state.check && !state.checkAuthRoutes && state.appLoaded">
            <div class="container-md mt-5">
                <div class="row align-items-center my-2">
                    <div class="col">
                        <div
                            class="alert alert-danger align-items-center text-center"
                            role="alert"
                        >
                            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                            You are unlogged ! Please login.
                                <router-link class="btn btn-outline-dark" :to="{ name: 'auth-login'}">
                                    Login
                                </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Display application content page from Router -->
        <div v-else class="col scrollarea">
            <!-- Router View -->
            <slot />
        </div>

    </main>

    <div>
        <footer class="py-3 my-3">
            <p class="text-center text-muted border-top">&copy; 2022 <span>{{ state.appName }} - v{{ state.appVersion }}</span></p>
        </footer>
    </div>

</template>

<script>
import { name, version } from "../../package.json"

// import Vue.js framework functions
import { reactive } from 'vue'
import { computed } from 'vue'
import { onMounted } from 'vue'

import useAuthComp from '@/composables/useAuthComp.js';
import { useAuth } from '@websanova/vue-auth/src/v3.js';
import { useRoute } from 'vue-router'


//import NavBar from '@/components/menus/NavBar.vue'
import SideBar from '@/components/menus/SideBar.vue'


export default {
    name: 'Layout',
    components: {
        //NavBar,
        SideBar,
    },
    setup() {

        const authComp = useAuthComp()
        const auth = useAuth()
        const route = useRoute()

        const appVersion = "v1.0.1"

        const state = reactive({

            appLoaded: false,

            check: computed(() => {
                return auth.check();
            }),

            user: computed(() => {
                return authComp.user();
            }),

            routeName: computed(() => {
                return route.name
            }),

            checkAuthRoutes: computed(() => {
                return (route.name === "auth-login") || (route.name === "auth-register") || (route.name === "forgot-password") || (route.name === "reset-password-form")
            }),

            appVersion: '0',
            appName: '-',

        })

        onMounted(() => {

            state.appName = name
            state.appVersion = version

            setTimeout(() => {
                state.appLoaded = true
            }, 3000)
        })


        return {
            state,
            appVersion,
        }
    }
}

</script>
