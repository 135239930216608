


// initial state
const state = {
    all: []
}


// getters
const getters = {

    getAllEvents ( state ) {
        return state.all
    },

}


// actions
const actions = {

    setAllEvents( { commit }, eventsList ) {
        commit( 'ADD_EVENTS_TO_ALL', { data: eventsList} )
    },


}



// mutations
const mutations = {

    ADD_EVENTS_TO_ALL( state, {data} ) {
        state.all = data
    },


}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
