import axios from 'axios';

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default (app) => {
    app.axios = axios;
    app.$http = axios;

    app.config.globalProperties.axios = axios;
    app.config.globalProperties.$http = axios;
}
