/*
|--------------------------------------------------------------------------
| Authentication composables utilities
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods used in
| the different components for the Authentication of the application.
*/

// import Vue.js features
import { reactive } from 'vue'

import { useRouter} from 'vue-router';
import { useAuth  } from '@websanova/vue-auth/src/v3.js';

export default function useAuthComp() {

    const auth   = useAuth();
    const router = useRouter();

    // define the reactive variables
    const state = reactive({
        form: {
            body: {
                frontend_url: '',
                name: '',
                //email: 'admin@test.com',
                email: '',
                token: null,
                //password: 'admin',
                password: '',
                password_confirmation: '',
                has_error: false,
            },
            show_password: false,
            remember_me: true,
            fetchUser: true,
            autoLogin: false,
            staySignedIn: true,
        },
    })

    function fetch(data) {
        return auth.fetch(data);
    }

    function refresh(data) {
        return auth.refresh(data);
    }

    function login(data) {
        data = data || {};

        return new Promise((resolve, reject) => {
            auth.login({
                url: 'auth/login',
                data: data.body,
                remember: data.remember,
                staySignedIn: data.staySignedIn,
            })
            .then((res) => {

                console.log(res)

                let user = res.data.data

                if (data.remember) {
                    auth.remember(JSON.stringify({
                        //name: res.response.data.user.name
                        //name: res.data.data.name
                        name: user
                    }));
                }

                let route = ''
                if ( user.role < 1 ) {
                    route = 'site-home'
                } else {
                    route = 'master-meals'
                }

                router.push({
                    //name: res.data.data.type + '-landing'
                    //name: 'site-home'
                    //name: 'master-meals'
                    name: route
                });

                resolve(res);
            }, reject);
        });
    }

    function register(data) {
        data = data || {};

        return new Promise((resolve, reject) => {
            auth.register({
                url: 'auth/register',
                data: data.body,
                autoLogin: data.autoLogin,
            })
            .then(() => {
                if (data.autoLogin) {
                    login(data).then(resolve, reject);
                }
            }, reject);
        });
    }

    function impersonate(data) {
        return auth.impersonate({
            url: 'auth/' + data.user.id + '/impersonate',
            redirect: {
                name: 'user-account'
            }
        });
    }

    function unimpersonate() {
        return auth.unimpersonate({
            redirect: {
                name: 'admin-users'
            }
        });
    }

    function logout() {
        return auth.logout();
    }

    function user() {
        return auth.user();
    }

    function masterUser() {
        return auth.user().role >= 2;
    }

    function impersonating() {
        return auth.impersonating();
    }

    return {
        state,
        fetch,
        refresh,
        login,
        register,
        impersonate,
        unimpersonate,
        logout,
        user,
        masterUser,
        impersonating,
    }
}
