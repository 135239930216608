<template>
    <div class="top-nav">
        <label id="hamburger_menu" for="hamburger_input"><i class="bi bi-list"></i></label>
        <a href="/">
            <img src="@/assets/logo.png"/>
        </a>

        <!--  ********************************** -->
        <!-- Select Locales for app language     -->
        <!--  ********************************** -->
        <div class="lang mt-2">
            <select v-model="$i18n.locale" class="form-select form-select-sm" aria-label=".form-select-sm example">
                <option v-for="(locale, i) in state.locales" :key="`locale-${i}`" :value="locale">
                    {{ locale }}
                </option>
            </select>
        </div>

    </div>

    <input type="checkbox" id="hamburger_input" class="burger-shower" />
    <div id="sidebar_menu" class="d-flex flex-column flex-shrink-0 text-white bg-dark collapse" style="width: 280px;">
        <label id="hamburger_menu_close" for="hamburger_input"><i class="bi bi-x-circle-fill"></i></label>
        <div class="mb-auto">
            <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-muted">
                <span class="text-primary">
                    {{ $t('navigation.titles.openLinks') }}
                </span>
            </h5>

            <ul class="nav nav-pills flex-column">

                <!--  ********************************** -->
                <!-- Links for the fixed site navigation -->
                <!--  ********************************** -->
                <li
                    v-for="(route, key) in getFixedRoutes()"
                    v-bind:key="route.path"
                    class="nav-item"
                >
                    <a
                        class="nav-link text-white"
                        @click.prevent="goto(route)"
                        :key="key"
                        v-bind:href="route.path"
                        v-bind:class="{ active: route.isActive }"
                    >
                        <i
                            v-bind:class="route.icone"
                        ></i>
                        {{ route.name }}
                    </a>
                </li>
            </ul>

            <!--  ************************** -->
            <!--  Authenticated users routes -->
            <!--  ************************** -->

        <!--
            <span v-if="$auth.check() && $auth.ready()">
        -->
            <span v-if="$auth.check(1) || $auth.check(2) || $auth.check(3)">

                <hr>
                <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span class="text-primary">
                        {{ $t('navigation.titles.loggedLinks') }}
                    </span>
                </h5>

                <ul class="nav nav-pills flex-column">

                    <!--  ********************************** -->
                    <!-- Links for the fixed site navigation -->
                    <!--  ********************************** -->
                    <li
                        v-for="(route, key) in getLoggedRoutes()"
                        v-bind:key="route.path"
                        class="nav-item"
                    >
                        <a
                            class="nav-link text-white"
                            @click.prevent="goto(route)"
                            :key="key"
                            v-bind:href="route.path"
                            v-bind:class="{ active: route.isActive }"
                        >
                            <i
                                v-bind:class="route.icone"
                            ></i>
                            {{ route.name }}
                        </a>
                    </li>
                </ul>
            </span>


            <!--  ************************** -->
            <!--  Authenticated master routes -->
            <!--  ************************** -->
            <span v-if="$auth.check(2) || $auth.check(3)">

                <hr>
                <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span class="text-primary">
                        {{ $t('navigation.titles.masterLinks') }}
                    </span>
                </h5>

                <ul class="nav nav-pills flex-column">

                    <!--  ********************************** -->
                    <!-- Links for the fixed site navigation -->
                    <!--  ********************************** -->

                    <li
                        v-for="(route, key) in getMasterRoutes()"
                        v-bind:key="route.path"
                        class="nav-item"
                    >
                        <a
                            class="nav-link text-white"
                            @click.prevent="goto(route)"
                            :key="key"
                            v-bind:href="route.path"
                            v-bind:class="{ active: route.isActive }"
                        >
                            <i
                                v-bind:class="route.icone"
                            ></i>
                            {{ route.name }}
                        </a>
                    </li>
                </ul>
            </span>


            <!--  ************************** -->
            <!--  Authenticated admin routes -->
            <!--  ************************** -->
            <span v-if="$auth.check(3)">

                <hr>
                <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span class="text-primary">
                        {{ $t('navigation.titles.adminLinks') }}
                    </span>
                </h5>

                <ul class="nav nav-pills flex-column">

                    <!--  ********************************** -->
                    <!-- Links for the fixed site navigation -->
                    <!--  ********************************** -->

                    <li
                        v-for="(route, key) in getAdminRoutes()"
                        v-bind:key="route.path"
                        class="nav-item"
                    >
                        <a
                            class="nav-link text-white"
                            @click.prevent="goto(route)"
                            :key="key"
                            v-bind:href="route.path"
                            v-bind:class="{ active: route.isActive }"
                        >
                            <i
                                v-bind:class="route.icone"
                            ></i>
                            {{ route.name }}
                        </a>
                    </li>
                </ul>
            </span>
        </div>

        <hr>


        <div class="dropdown" v-if="state.auth.check && state.auth.ready">
            <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownUserLogged" data-bs-toggle="dropdown" aria-expanded="false" ref="userdropdown">
                <strong>
                    <i class="bi bi-person-circle"></i> &nbsp;
                    {{ $auth.user().name }}
                </strong>
            </button>
            <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUserLogged">

                <li><a class="dropdown-item" href="#">Settings</a></li>
                <li><a class="dropdown-item" href="#">Profile</a></li>
                <li><hr class="dropdown-divider"></li>

                <li>
                    <a class="dropdown-item" href="#" @click.prevent="logout()">
                        Sign out
                    </a>
                </li>

            </ul>
        </div>

        <div v-else>
            <button @click.prevent="login()" type="button" class="btn btn-outline-light">Sign in</button>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, onUpdated } from 'vue'

import { useAuth } from '@websanova/vue-auth/src/v3.js';
import { useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'

import { Dropdown } from 'bootstrap';

export default {
    name: 'NewSideBar',

    components: {
        //AppLink,
    },

    setup() {
        const auth = useAuth();
        const router = useRouter();

        const { t } = useI18n({ useScope: 'global' })
        const userdropdown = ref(null);

        const state = reactive({
            locales: ["fr", "en", "de"],

            userdorpdownListenerAdded: false,

            auth: {
                check: computed(() => {
                    return auth.check();
                }),

                ready: computed(() => {
                    return auth.ready();
                }),
            },

            routes : {
                // fixed routes to navigate in the app
                fixed: [
                    { name: t('navigation.home'), path: 'site-home', icone: 'bi bi-house-door', isActive: false },
                    //{ name: 'Jumbotron', path: 'site-jumbotron', icone: 'bi bi-image', isActive: false },
                    // { name: 'About', path: 'site-about', icone: 'bi bi-question-square', isActive: false },
                    // { name: 'Dashboard', path: 'site-dashboard', icone: 'bi bi-speedometer2', isActive: false },
                    // { name: 'Album', path: 'site-album', icone: 'bi bi-journal-album', isActive: false },
                    // { name: 'Carousel', path: 'site-carousel', icone: 'bi bi-signpost-split', isActive: false },
                ],

                // routes available only for unlogged user
                unlogged: [
                    { name: 'Login', path: 'auth-login' },
                    { name: 'Register', path: 'auth-register' },
                    //{ name: 'Logout', path: 'user-logout' },
                ],

                // Logged user
                logged: [
                    //{ name: 'Logged', path: 'logged-user', icone: 'bi bi-people', isActive: false  },
                    //{ name: 'Users store', path: 'admin-users-store', icone: 'bi bi-person-lines-fill' },
                    { name: t('navigation.meals'), path: 'master-meals', icone: 'bi bi-vinyl-fill', isActive: false  },
                ],

                // MASTER
                master: [
                    { name: t('navigation.customers'), path: 'master-customers', icone: 'bi bi-people', isActive: false  },
                    // { name: 'Calendar', path: 'master-calendar', icone: 'bi bi-calendar3', isActive: false  },
                    //{ name: 'Meals', path: 'master-meals', icone: 'bi bi-vinyl-fill', isActive: false  },
                    //{ name: 'Users store', path: 'admin-users-store', icone: 'bi bi-person-lines-fill' },
                ],

                // ADMIN
                admin: [
                    { name: t('navigation.users'), path: 'admin-users', icone: 'bi bi-people', isActive: false  },
                    //{ name: 'Users store', path: 'admin-users-store', icone: 'bi bi-person-lines-fill' },
                ],
            }
        })

        onUpdated(() => {
            var elem = userdropdown.value

            if (elem) {
                elem.addEventListener('click', function() {
                    var myDropdown = Dropdown.getOrCreateInstance(elem);
                    myDropdown.toggle();
                })
            }
        });

        function getFixedRoutes() {
            let list = [
                { name: t('navigation.home'), path: 'site-home', icone: 'bi bi-house-door', isActive: false },
            ]
            return list
        }

        function getLoggedRoutes() {
            let list = [
                { name: t('navigation.customers'), path: 'master-customers', icone: 'bi bi-people', isActive: false  },
                { name: t('navigation.meals'), path: 'master-meals', icone: 'bi bi-vinyl-fill', isActive: false  },
                { name: t('navigation.deliveries'), path: 'master-deliveries', icone: 'bi bi-truck', isActive: false  },
            ]
            return list
        }

        function getMasterRoutes() {
            let list = [
                { name: t('navigation.invoices'), path: 'master-invoices', icone: 'bi bi-receipt', isActive: false  },
                { name: t('navigation.menus'), path: 'master-menus', icone: 'bi bi-book', isActive: false  },
                { name: t('navigation.items'), path: 'master-items', icone: 'bi bi-shop-window', isActive: false  },
                //{ name: t('navigation.prices'), path: 'master-prices', icone: 'bi bi-cash-coin', isActive: false  },
            ]
            return list
        }

        function getAdminRoutes() {
            let list = [
                { name: t('navigation.users'), path: 'admin-users', icone: 'bi bi-people', isActive: false  },
            ]
            return list
        }

        function closeSideMenu() {
          document.getElementById('hamburger_input').checked = false;
        }

        function goto(arg) {
            closeSideMenu()

            // reset all active class before push route
            for (var prop in state.routes) {
                state.routes[prop].forEach( el => el.isActive = false )
            }

            router.push({ name: arg.path })

            arg.isActive = true
        }

        function logout() {
            auth.logout({
                //redirect: {name : 'site-home'}
                redirect: {name : 'auth-login'}
            })
            closeSideMenu()
        }

        function login() {
            router.push({ name: 'auth-login' })
            closeSideMenu()
        }

        return {
            state,
            goto,
            login,
            logout,
            getFixedRoutes,
            getLoggedRoutes,
            getMasterRoutes,
            getAdminRoutes,
            userdropdown
        }
    },
}
</script>

<style>
    @import '../../assets/css/sidebars.css';
</style>
