import { createApp } from 'vue';
//import {provide} from 'vue';
//import App         from './views/Index.vue';
import App         from './App.vue';
import store       from './store';
import router      from './router';
import auth        from './plugins/auth.js';
import http        from './config/http';
import dateTime        from './config/dateTime';
import i18n from './i18n.js';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Create App instance
const app = createApp(App);

// Use App modules
app
.use(http)
.use(store)
.use(router)
.use(auth)
.use(dateTime)
.use(i18n)
.mount('#app');
