import { createStore, createLogger } from 'vuex'
//import cart from './modules/cart'
//import products from './modules/products'
import users from './modules/users'
import events from './modules/events'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        users,
        events
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
