export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue 3 i18n"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Vue i18n next example using vue-i18n-next"])},
  "navigation": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "meals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meals"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "deliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliveries"])},
    "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "titles": {
      "openLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open links"])},
      "loggedLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links for User"])},
      "masterLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links for Master"])},
      "adminLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links for Admin"])}
    }
  },
  "titles": {
    "userList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User list"])},
    "customerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer list"])},
    "mealList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal list"])},
    "invoiceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice list"])},
    "deliveryList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery list"])},
    "menuList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu list"])},
    "itemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item list"])},
    "priceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list"])},
    "invoiceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice number"])}
  },
  "dateTime": {
    "Week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
    "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
    "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "monthsLong": {
      "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
      "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
      "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
      "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
      "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
      "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
      "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
    }
  },
  "objects": {
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])}
  },
  "models": {
    "user": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
    },
    "meal": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "group_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group ID"])},
      "invoice_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice ID"])},
      "meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meal"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
      "portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portions"])},
      "half_portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1/2 portions"])},
      "portion_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per portion"])},
      "half_portion_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per 1/2 portion"])},
      "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totals"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "repetitions": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there any repetition?"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What type of repetition?"])},
        "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique event"])},
        "every_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every week"])},
        "nb_occurrences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of occurrences"])},
        "until_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until a given date"])}
      },
      "validations": {
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the start date..."])},
        "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the end date..."])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up the customer name!"])},
        "portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up the amount of portions!"])},
        "half_portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up the amount of 1/2 portions!"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with a comment!"])},
        "occurrences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up the amount of occurrences!"])}
      }
    },
    "invoice": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline"])},
      "validations": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up the amount of discount!"])}
      },
      "generateInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate invoice"])},
      "deleteInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete invoice"])},
      "statusFilter": {
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
        "invoiced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoiced"])},
        "printed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printed"])}
      }
    },
    "menu": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "validations": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the date..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up the menu title!"])}
      }
    },
    "customer": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer ID"])},
      "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "locality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locality"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer information"])},
      "validations": {
        "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the civility..."])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the lastname!"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the firstname!"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the name of the street!"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the building number!"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the zip code!"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the location!"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the phone number!"])},
        "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the mobile phone number!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the email address!"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the status..."])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer specific information"])}
      }
    },
    "item": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
      "validations": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the name!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the description!"])}
      }
    },
    "price": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "item_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item id"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "validity_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity from"])},
      "validity_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity to"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "validations": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill up with the amount!"])},
        "validity_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the start date of validity..."])},
        "validity_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the end date of validity..."])}
      }
    }
  },
  "form": {
    "actions": {
      "filtersOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters on"])},
      "newCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New customer"])},
      "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user"])},
      "newMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New menu"])},
      "newItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New item"])},
      "newPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New price"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
      "printPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print as pdf"])},
      "printLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Labels"])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : a single element or several in the series ?"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "invoices": {
        
      }
    },
    "scope": {
      "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["only one"])},
      "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from now"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])}
    },
    "cards": {
      "meals": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new meal"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit meal"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show meal"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete meal"])}
        }
      },
      "invoices": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new invoice"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit invoice"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show invoice"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete invoice"])}
        }
      },
      "customers": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new customer"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit customer"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show customer"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete customer"])}
        }
      },
      "users": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new user"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show user"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])}
        }
      },
      "menus": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new menu"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit menu"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show menu"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete menu"])}
        }
      },
      "items": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new item"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit item"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show item"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete item"])}
        }
      },
      "prices": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new price"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit price"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show price"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete price"])}
        }
      }
    },
    "infos": {
      "edit": {
        "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit only one resource."])},
        "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit all futur resources from this one."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit all resource t in the complete serie."])}
      },
      "delete": {
        "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete only one resource."])},
        "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all futur resources from this one."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all resource t in the complete serie."])},
        "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this resource?"])}
      }
    },
    "options": {
      "weekdaysShort": {
        "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
        "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu"])},
        "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We"])},
        "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Th"])},
        "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
        "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
        "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])}
      },
      "weekdaysLong": {
        "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
        "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
        "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedneday"])},
        "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
        "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
        "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
        "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])}
      }
    }
  },
  "alerts": {
    "invoices": {
      "noMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Meals registered in the current month!"])},
      "noInvoiceWithSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoices to display with this selection!"])}
    }
  }
}