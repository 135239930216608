export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue 3 i18n"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un exemple Vue i18n next avec vue-i18n-next"])},
  "navigation": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "meals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repas"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "deliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraisons"])},
    "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "titles": {
      "openLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens ouverts"])},
      "loggedLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens pour User"])},
      "masterLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens pour Master"])},
      "adminLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens pour Admin"])}
    }
  },
  "titles": {
    "userList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des utilisateurs"])},
    "customerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des clients"])},
    "mealList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des repas"])},
    "invoiceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des factures"])},
    "deliveryList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des livraisons"])},
    "menuList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des menus"])},
    "itemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des articles"])},
    "priceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des prix"])},
    "invoiceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de facture"])}
  },
  "dateTime": {
    "Week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
    "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
    "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
    "monthsLong": {
      "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
      "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
      "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
      "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
      "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
      "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
      "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
      "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
      "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])}
    }
  },
  "objects": {
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elément"])}
  },
  "models": {
    "user": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour le"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
    },
    "meal": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "group_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de groupe"])},
      "invoice_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de facture"])},
      "meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repas"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de départ"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
      "portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portions"])},
      "half_portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1/2 portions"])},
      "portion_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix par portion"])},
      "half_portion_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix par 1/2 portion"])},
      "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaux"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantité"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "repetitions": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y a-t-il répétition ?"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel type de répétition ?"])},
        "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evénement unique"])},
        "every_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque semaine"])},
        "nb_occurrences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'occurences"])},
        "until_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à une date donnée"])}
      },
      "validations": {
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date de départ..."])},
        "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date de fin..."])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nom du client !"])},
        "portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nombre de portions !"])},
        "half_portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nombre de 1/2 portions !"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un commentaire !"])},
        "repetition_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez séectionner le type de répétition !"])},
        "occurrences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nombre d'occurrences !"])},
        "weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner les jours de la semaine !"])}
      }
    },
    "invoice": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabais"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echéance"])},
      "validations": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entre le montant de la remise !"])}
      },
      "generateInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer la facture"])},
      "deleteInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la facture"])},
      "statusFilter": {
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverte"])},
        "invoiced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturée"])},
        "printed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimée"])}
      }
    },
    "menu": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregister"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "validations": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le titre !"])}
      }
    },
    "customer": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du client"])},
      "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPA"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "locality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations client"])},
      "validations": {
        "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la civilité..."])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nom !"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le prénom !"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nom de la rue !"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le numéro du bâtiment !"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le code postal !"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer la localité !"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le numéro de téléphone !"])},
        "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le numéro de téléphone mobile !"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer l'adresse e-mail !"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectrionner le statut..."])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations spécifiques au client"])}
      }
    },
    "item": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour le"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "validations": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nom !"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer la description !"])}
      }
    },
    "price": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
      "item_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id article"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "validity_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité du"])},
      "validity_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité au"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour le"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "validations": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le montant !"])},
        "validity_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date de début de validité..."])},
        "validity_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date de fin de validité..."])}
      }
    }
  },
  "form": {
    "actions": {
      "filtersOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres sur"])},
      "newCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau client"])},
      "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel utilisateur"])},
      "newMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau menu"])},
      "newItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel article"])},
      "newPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau prix"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
      "printPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer en pdf"])},
      "printLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer étiquettes"])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : un seul élément ou plusieurs dans la série ?"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
    },
    "scope": {
      "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un seul"])},
      "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dès maintenant"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tous"])}
    },
    "cards": {
      "meals": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau repas"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer un repas"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher un repas"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un repas"])}
        }
      },
      "invoices": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle facture"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer une facture"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher une facture"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer une facture"])}
        }
      },
      "customers": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau client"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer un client"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher un client"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un client"])}
        }
      },
      "users": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouvel utilisateur"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer un utilisateur"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher un utilisateur"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un utilisateur"])}
        }
      },
      "menus": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau menu"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer un menu"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher un menu"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un menu"])}
        }
      },
      "items": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouvel article"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer un article"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher un article"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un article"])}
        }
      },
      "prices": {
        "headers": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau prix"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer un prix"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher un prix"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un prix"])}
        }
      }
    },
    "infos": {
      "edit": {
        "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer une seule ressource."])},
        "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer toutes les futures ressources à partir de celle-ci."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer toutes les ressources de la série complète."])}
      },
      "delete": {
        "onlyOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer une seule ressource."])},
        "fromNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer toutes les futures ressources à partir de celle-ci."])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer toutes les ressources de la série complète."])},
        "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer cette ressource ?"])}
      }
    },
    "options": {
      "weekdaysShort": {
        "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lu"])},
        "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma"])},
        "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
        "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je"])},
        "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve"])},
        "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
        "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])}
      },
      "weekdaysLong": {
        "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
        "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
        "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
        "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
        "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
        "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])},
        "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])}
      }
    }
  },
  "alerts": {
    "invoices": {
      "noMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun repas enregistré dans le mois courant !"])},
      "noInvoiceWithSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune facture à afficher avec cette sélection !"])}
    }
  }
}