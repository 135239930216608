<template>
    <ElementLayout>
        <router-view />
    </ElementLayout>
</template>

<script>
// @ is an alias to /src
import ElementLayout from '@/elements/Layout.vue';

export default {
    name: 'App',
    components: {
        ElementLayout
    },
}
</script>
